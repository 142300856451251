export function formatMoreText(text: string): string {
  return text
    .split('\n')
    .map((line) => {
      if (line.startsWith('-')) line = line.replace(/^-/gi, '* ')
      return line
    })
    .join('\n')
}

export function pluralize(count: number, singular: string, plural: string): string {
  return `${count} ${count == 1 ? singular : plural}`
}
